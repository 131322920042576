import { useEffect, useState, useRef } from 'react';

export default function useInterval(callback, time, delay = 0) {
  const [started, setIsStarted] = useState(false);
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    setTimeout(() => setIsStarted(true), delay);

    if (started) {
      if (time !== null) {
        let id = setInterval(tick, time);
        return () => clearInterval(id);
      }
    }
  }, [time, started]);
}
