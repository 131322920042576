import React from 'react';

const Menu = ({ height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      fill="none"
      viewBox="0 96 960 960"
    >
      <path
        fill="currentColor"
        d="M153.333 816q-14.166 0-23.75-9.617Q120 796.766 120 782.55q0-14.216 9.583-23.716 9.584-9.5 23.75-9.5h653.334q14.166 0 23.75 9.617Q840 768.568 840 782.784q0 14.216-9.583 23.716-9.584 9.5-23.75 9.5H153.333Zm0-206.667q-14.166 0-23.75-9.617Q120 590.099 120 575.883q0-14.216 9.583-23.716 9.584-9.5 23.75-9.5h653.334q14.166 0 23.75 9.617Q840 561.901 840 576.117q0 14.216-9.583 23.716-9.584 9.5-23.75 9.5H153.333Zm0-206.667q-14.166 0-23.75-9.617Q120 383.432 120 369.216q0-14.216 9.583-23.716 9.584-9.5 23.75-9.5h653.334q14.166 0 23.75 9.617Q840 355.234 840 369.45q0 14.216-9.583 23.716-9.584 9.5-23.75 9.5H153.333Z"
      />
    </svg>
  );
};

export default Menu;

Menu.defaultProps = {
  height: '24px',
};
