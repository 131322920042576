import { useNavigate, useParams } from 'react-router-dom';

import useBeforeUnload from 'hooks/useBeforeUnload';
import useTopScorers from 'hooks/useTopScorers';
import Player from 'components/Player';
import Button from 'components/Button';

import styles from './Board.module.css';

const ordinalNumbers = ['st', 'nd', 'rd', 'th', 'th'];

const Board = () => {
  useBeforeUnload();
  const navigate = useNavigate();
  const { id } = useParams();

  const topScorers = useTopScorers(id);

  const goNext = () => {
    navigate('/quiz');
  };

  return (
    <main className={styles.container}>
      <section className={styles.content}>
        <h1>Leader Board</h1>
        <div className={styles.players}>
          {topScorers.map((scorer, index) => {
            const ranking = index + 1;
            const ordinalNumber = ordinalNumbers[index];
            return (
              <div key={scorer.id} className={styles.player}>
                <div>
                  {ranking}
                  <span className={styles.ordinal}>{ordinalNumber}</span>
                </div>
                <div className={styles.playerBox}>
                  <Player
                    size={50}
                    round
                    id={scorer.id}
                    className={styles.avatar}
                  ></Player>
                </div>
                <div className={styles.score}>
                  <p>{scorer.recent > 0 ? `+${scorer.recent}` : '\u00A0'}</p>
                  <p>{scorer.total}</p>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <section className={styles.next}>
        <Button onClick={goNext}>Next</Button>
      </section>
    </main>
  );
};

export default Board;
