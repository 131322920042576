import { useEffect, useState, useCallback } from 'react';
import fscreen from 'fscreen';

import { ShowFullscreen, ExitFullscreen } from 'components/Icons';

import styles from './FullscreenButton.module.css';

const FullscreenButton = ({ className }) => {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  if (!fscreen.fullscreenEnabled) {
    // fullscreen not supported
    return null;
  }

  useEffect(() => {
    const handler = () => {
      forceUpdate();
    };
    fscreen.addEventListener('fullscreenchange', handler);
    return () => {
      fscreen.removeEventListener('fullscreenerror', handler);
    };
  }, []);

  const cx = className ? `${styles.button} ${className}` : styles.button;

  const toggleFullScreen = () => {
    const notInFullScreen = fscreen.fullscreenElement === null;

    const action = notInFullScreen
      ? () => fscreen.requestFullscreen(document.body)
      : fscreen.exitFullscreen;
    action();
  };

  return (
    <button onClick={toggleFullScreen} className={cx}>
      {fscreen.fullscreenElement === null ? (
        <ShowFullscreen height={40} />
      ) : (
        <ExitFullscreen height={40} />
      )}
    </button>
  );
};

export default FullscreenButton;
