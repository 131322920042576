import React from 'react';

import { More } from '../Icons';

import styles from './MoreButton.module.css';

const MoreButton = React.forwardRef((props, ref) => {
  const { active, children, className } = props;

  let cx = active ? `${styles.button} ${styles.active}` : styles.button;
  cx = className ? `${cx} ${className}` : cx;

  return (
    <button ref={ref} {...props} className={cx}>
      {children ? children : <More height={34} />}
    </button>
  );
});

export default MoreButton;
