import { useRef, useEffect } from 'react';

const defaultConfig = {
  volume: 1,
  playbackRate: 1,
};

const useAudio = (src, config) => {
  const { volume, playbackRate } = { ...defaultConfig, ...config };

  const audio = useRef(new Audio(src));

  useEffect(() => {
    audio.current.volume = volume;
  }, [volume]);

  useEffect(() => {
    audio.current.playbackRate = playbackRate;
  }, [playbackRate]);

  return audio.current;
};

export default useAudio;
