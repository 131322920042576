import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useNavigate, useParams } from 'react-router-dom';

import Button from 'components/Button';
import QuestionWithAnswers from '../Question/QuestionWithAnswers';
import QuestionsCount from '../Question/QuestionsCount';

import useBeforeUnload from 'hooks/useBeforeUnload';
import useQuizScoring from 'hooks/useQuizScoring';
import { questionsAtomFamily } from 'atoms/questions';
import { answeredQuestionsAtom, withIsLastQuestion } from 'atoms/questions';
import { withAnswersCount } from 'atoms/quiz';

import styles from './Result.module.css';

const Result = () => {
  useBeforeUnload();
  const navigate = useNavigate();
  const { id } = useParams();
  const updateAnsweredQuestions = useSetRecoilState(answeredQuestionsAtom);
  const { question, answers } = useRecoilValue(questionsAtomFamily(id));
  const isLastQuestion = useRecoilValue(withIsLastQuestion(id));
  const answersCount = useRecoilValue(withAnswersCount);

  useQuizScoring(id);

  const goNext = () => {
    updateAnsweredQuestions((answeredQuestions) => [...answeredQuestions, id]);

    navigate(isLastQuestion ? '/quiz/podium' : '/quiz/board/' + id);
  };

  const resultAnswers = answers.map((answer, index) => ({
    ...answer,
    answers: Object.values(answersCount)[index],
  }));

  return (
    <main className={styles.container}>
      <section className={styles.quantity}>
        <QuestionsCount />
      </section>

      <section className={styles.question}>
        <QuestionWithAnswers
          question={question}
          answers={resultAnswers}
          asResult
        />
      </section>
      <section className={styles.next}>
        <Button onClick={goNext}>Next</Button>
      </section>
    </main>
  );
};

export default Result;
