import React from 'react';
import { Link } from 'react-router-dom';

import styles from './DownloadLink.module.css';

const DownloadLink = () => {
  return (
    <Link
      to="/template.json"
      target="_blank"
      download
      className={styles.download}
    >
      Download it here.
    </Link>
  );
};

export default DownloadLink;
