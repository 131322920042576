import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import { v4 as uuid } from 'uuid';

export const questionsAtom = atom({
  key: 'questions.questions',
  default: [],
});

export const answeredQuestionsAtom = atom({
  key: 'questions.answered',
  default: [],
});

export const questionsAtomFamily = atomFamily({
  key: 'questions.questionsFamily',
  default: null,
});

export const withQuestionsCount = selector({
  key: 'questions.count',
  get: ({ get }) => {
    const questions = get(questionsAtom);
    const answered = get(answeredQuestionsAtom);
    return {
      total: questions.length,
      answered: answered.length,
    };
  },
});

export const withNextQuestion = selector({
  key: 'questions.nextQuestion',
  get: ({ get }) => {
    const questions = get(questionsAtom);
    const answeredQuestions = get(answeredQuestionsAtom);
    const unansweredQuestions = questions.filter(
      (question) => !answeredQuestions.includes(question)
    );

    return unansweredQuestions.length > 0 ? unansweredQuestions[0] : null;
  },
});

export const withAllQuestions = selector({
  key: 'questions.withAllQuestions',
  get: ({ get }) => {
    const players = get(questionsAtom);
    return players.map((id) => {
      return get(questionsAtomFamily(id));
    });
  },
  set: ({ set }, questions) => {
    questions = questions.map((question) => ({ ...question, id: uuid() }));
    const identifiers = questions.map((question) => question.id);
    set(questionsAtom, identifiers);
    questions.forEach((question) => {
      const id = question.id;
      set(questionsAtomFamily(id), question);
    });
  },
});

export const withIsCurrentQuestionsMultiCheck = selectorFamily({
  key: 'questions.withIsCurrentQuestionMulticheck',
  get:
    (id) =>
    ({ get }) => {
      const currentQuestion = get(questionsAtomFamily(id));
      if (!currentQuestion) {
        return false;
      }
      const correctAnswers = currentQuestion.answers.filter(
        ({ correct }) => correct
      );
      return correctAnswers.length > 1;
    },
});

export const withIsLastQuestion = selectorFamily({
  key: 'questions.withIsLastQuestions',
  get:
    (id) =>
    ({ get }) => {
      const allQuestions = get(questionsAtom);
      return allQuestions[allQuestions.length - 1] === id;
    },
});

export const withQuestionsReady = selector({
  key: 'questions.ready',
  get: ({ get }) => {
    const questions = get(questionsAtom);
    return questions && questions.length > 0;
  },
});
