import { Check } from 'components/Icons';

import styles from './QuestionWithAnswers.module.css';

const colors = ['blue', 'orange', 'green', 'yellow'];

const QuestionWithAnswers = ({ question, answers = [], asResult }) => {
  if (!question) {
    return null;
  }

  const usedColors = colors.slice(0, answers.length);

  return (
    <section className={styles.container}>
      <div className={styles.question}>{question}</div>
      {usedColors.map((color, index) => {
        const style = {
          ['--input-color']: `var(--${color})`,
        };

        const showCheck = asResult && answers[index].correct;
        const showAnswersCount = asResult && answers[index].answers > 0;

        const boxStyles =
          asResult && !answers[index].correct
            ? `${styles.box} ${styles.ghosted}`
            : styles.box;

        const countStyles = !answers[index].correct
          ? `${styles.count} ${styles.ghostedcount}`
          : styles.count;

        return (
          <div className={styles.answer} style={style} key={color}>
            {showAnswersCount && (
              <div className={countStyles}>
                <span>{answers[index].answers}</span>
              </div>
            )}

            <div className={boxStyles}>
              <span>{answers[index].text}</span>
            </div>

            {showCheck && (
              <div className={styles.check}>
                <Check size={50} strokeWidth={2.5} />
              </div>
            )}
          </div>
        );
      })}
    </section>
  );
};

export default QuestionWithAnswers;
