import Ajv from 'ajv/dist/jtd';
const ajv = new Ajv({ allErrors: true });

const schema = {
  elements: {
    properties: {
      answers: {
        elements: {
          properties: {
            correct: { type: 'boolean' },
            text: { type: 'string' },
          },
        },
      },
      question: { type: 'string' },
    },
  },
};

const validateInternal = ajv.compile(schema);

export default function validate(json) {
  json = json.trim();

  let data = null;
  try {
    data = JSON.parse(json);
  } catch (err) {
    return {
      valid: false,
      errors: err.message,
    };
  }

  const valid = validateInternal(data);

  return {
    valid,
    errors: valid ? [] : [...validateInternal.errors],
  };
}
