import { useState, useEffect } from 'react';

import useInterval from 'hooks/useInterval';

import styles from './Timer.module.css';

const Timer = ({ started, timeout, size, onExpired }) => {
  const [count, setCount] = useState(timeout);
  const [time, setTime] = useState(0);
  const [isStarted, setIsStarted] = useState(started);

  const progress = (time / timeout) * -100;

  const progressStyles = isStarted
    ? styles.progress
    : `${styles.progress} ${styles.reset}`;

  useEffect(() => {
    setTime(0);
    setCount(timeout);
  }, [timeout]);

  useEffect(() => {
    if (started) {
      setTimeout(() => {
        setIsStarted(true);
        setCount(timeout);
      }, 20);

      setTime(0);
    }
  }, [started]);

  useInterval(() => {
    if (!isStarted) {
      return;
    }

    if (time < timeout) {
      setTime(time + 1);
      setTimeout(() => setCount(count - 1), 300);
    }

    if (time >= timeout) {
      setIsStarted(false);
      onExpired();
    }
  }, 1000);

  return (
    <div className={styles.container}>
      <svg
        viewBox="-1 -1 34 34"
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
        className={styles.timer}
      >
        <circle
          cx="16"
          cy="16"
          r="15.9155"
          className={progressStyles}
          style={{ strokeDashoffset: progress }}
        />
      </svg>
      <div className={styles.count} style={{ fontSize: size * 0.5 }}>
        {count}
      </div>
    </div>
  );
};

export default Timer;

Timer.defaultProps = {
  timeout: 20,
  size: 80,
};
