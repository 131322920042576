import { MultiCheck } from 'components/Icons';

import styles from './MultiCheckHint.module.css';

const MultiCheckHint = () => {
  return (
    <div className={styles.hint}>
      <MultiCheck />
      <div className={styles.text}>Multiple answers are possible...</div>
    </div>
  );
};

export default MultiCheckHint;
