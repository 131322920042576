import Button from 'components/Button';
import Batch from 'components/Batch';

import styles from './EmptyState.module.css';

const EmptyState = ({ onClick }) => {
  return (
    <>
      <section className={styles.empty}>
        <p>Get ready to assign your buzzers!</p>

        <ol>
          <li>Check if your name is on the screen</li>
          <li>
            Hold down your <Batch color="red" /> buzzer
          </li>
          <li>Wait for the confirmation message to appear on the screen</li>
        </ol>

        <p>Remember, only one buzzer per player name.</p>
      </section>

      <Button className={styles.button} onClick={onClick}>
        Get ready
      </Button>
    </>
  );
};

export default EmptyState;
