import { useState } from 'react';
import useInterval from 'hooks/useInterval';

import styles from './ReadySet.module.css';

const ReadySet = ({ active, texts, time, delay, onAnimationEnd }) => {
  const [index, setIndex] = useState(0);

  const style = {
    animationDelay: `${delay}ms`,
    animationDuration: `${time}ms`,
  };

  useInterval(
    () => {
      if (!active) {
        return;
      }
      if (index + 1 < texts.length) {
        setIndex((index) => index + 1);
      } else {
        onAnimationEnd();
      }
    },
    time,
    delay
  );

  if (!active) {
    return null;
  }

  return (
    <h1 className={styles.default} style={style}>
      {texts[index % texts.length]}
    </h1>
  );
};

export default ReadySet;

ReadySet.defaultProps = {
  active: true,
  texts: [],
  time: 2000,
  delay: 1000,
  onAnimationEnd: () => {},
};
