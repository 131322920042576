import React, { useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { withQuestionsReady } from 'atoms/questions';

import BackButton from 'components/BackButton';
import { More, MoreAdd, MoreExport, MoreSortable } from './More';

import EmptyState from './EmptyState';
import SortableQuestionsList from './SortableQuestionsList';

import useBeforeUnload from 'hooks/useBeforeUnload';

import styles from './Questions.module.css';

const Questions = () => {
  const navigate = useNavigate();
  useBeforeUnload();

  const [sortable, toggleSortable] = useReducer((state) => !state, false);
  const hasQuestions = useRecoilValue(withQuestionsReady);
  const cx = !hasQuestions
    ? `${styles.container} ${styles.center}`
    : styles.container;

  return (
    <main className={cx}>
      <BackButton className={styles.back} onClick={() => navigate(-1)} />

      <div className={styles.more}>
        {hasQuestions && (
          <More className={styles.morebutton}>
            <MoreAdd active={!sortable} />
            <MoreExport />
            <MoreSortable active={sortable} toggle={toggleSortable} />
          </More>
        )}
      </div>

      <header className={styles.header}>
        <h1>Setup questions</h1>
      </header>

      {!hasQuestions && (
        <div className={styles.empty}>
          <EmptyState />
        </div>
      )}

      <section className={styles.questions}>
        <SortableQuestionsList sortable={sortable} />
      </section>
    </main>
  );
};

export default Questions;
