import { atom, atomFamily, selector } from 'recoil';
import { playersAtom } from './players';

export const buzzersAtom = atom({
  key: 'buzzers.buzzers',
  default: [],
});

export const buzzersAtomFamily = atomFamily({
  key: 'buzzers.buzzersFamily',
  default: null,
});

export const withAllBuzzers = selector({
  key: 'buzzers.allBuzzers',
  get: ({ get }) => {
    const allBuzzers = get(buzzersAtom);
    return allBuzzers.map((id) => ({
      id,
      player: get(buzzersAtomFamily(id)),
    }));
  },
});

export const withPlayersWithBuzzers = selector({
  key: 'buzzers.playersWithBuzzers',
  get: ({ get }) => {
    const allBuzzers = get(buzzersAtom);
    return allBuzzers.map((id) => get(buzzersAtomFamily(id)));
  },
});

export const withPlayersWithoutBuzzers = selector({
  key: 'buzzers.playersWithoutBuzzers',
  get: ({ get }) => {
    const players = get(playersAtom);
    const playersWithBuzzer = get(withPlayersWithBuzzers);
    return players.filter((id) => !playersWithBuzzer.includes(id));
  },
});

export const withRemoveBuzzerByPlayer = selector({
  key: 'buzzers.withRemoveBuzzerByPlayer',
  get: () => {},
  set: ({ get, set }, id) => {
    const allBuzzers = get(buzzersAtom);
    const playersWithBuzzer = allBuzzers.map((buzzerId) => ({
      buzzer: buzzerId,
      player: get(buzzersAtomFamily(buzzerId)),
    }));

    const currentPlayer = playersWithBuzzer.find((item) => item.player === id);
    const currentPlayerHasBuzzer = currentPlayer != null;

    if (currentPlayerHasBuzzer) {
      set(buzzersAtomFamily(currentPlayer.buzzer), null);
      set(
        buzzersAtom,
        allBuzzers.filter((id) => id != currentPlayer.buzzer)
      );
    }
  },
});

export const withBuzzersReady = selector({
  key: 'buzzers.ready',
  get: ({ get }) => {
    const buzzers = get(buzzersAtom);
    const playersWithoutBuzzer = get(withPlayersWithoutBuzzers);
    return buzzers.length > 0 && playersWithoutBuzzer.length === 0;
  },
});
