import { useRecoilValue } from 'recoil';

import { withAllScorings, scoringAtomFamily } from 'atoms/scoring';
import { getTotalPlayersScorings } from 'util/scorings';

export default function useTopScorers(currentQuestionId) {
  const allScorings = useRecoilValue(withAllScorings);
  const currentScoring = useRecoilValue(scoringAtomFamily(currentQuestionId));

  if (!currentQuestionId) {
    return [];
  }

  const allScorers = getTotalPlayersScorings(allScorings);
  const topScorers = allScorers
    .slice(0, allScorers.length > 5 ? 5 : allScorers.length)
    .map((scorer) => ({
      ...scorer,
      recent: Math.floor(
        currentScoring.scorings.find((scoring) => scoring.player === scorer.id)
          ?.scoring || 0
      ),
    }));

  return topScorers;
}
