import styles from './Textarea.module.css';

const Textarea = ({ value, placeholder, onChange, className, ...props }) => {
  const cx = className ? `${styles.textarea} ${className}` : styles.textarea;

  return (
    <div className={styles.container}>
      <textarea
        placeholder={placeholder}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        className={cx}
        {...props}
      />
    </div>
  );
};

export default Textarea;
