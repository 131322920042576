import React from 'react';

import styles from './Avatar.module.css';

const Avatar = ({ code, size = 80, round }) => {
  const cx = round ? `${styles.round} ${styles.avatar}` : styles.avatar;

  return (
    <img
      width={size}
      height={size}
      src={`/avatars/${code}.png`}
      alt="Avatar"
      className={cx}
    />
  );
};

export default Avatar;
