import { selector } from 'recoil';

import { withPlayersReady } from './players';
import { withQuestionsReady, answeredQuestionsAtom } from './questions';
import { withBuzzersReady } from './buzzers';
import { quizQuestionsAtom, quizQuestionsAtomFamily } from './quiz';
import { scoringAtom, scoringAtomFamily } from './scoring';

export const withSetupReady = selector({
  key: 'common.ready',
  get: ({ get }) => {
    const playersReady = get(withPlayersReady);
    const questionsReady = get(withQuestionsReady);
    const buzzersReady = get(withBuzzersReady);

    return playersReady && questionsReady && buzzersReady;
  },
});

export const withResetAll = selector({
  key: 'common.withResetAll',
  get: () => {},
  set: ({ get, reset }) => {
    const allQuizQuestions = get(quizQuestionsAtom);
    const allScorings = get(scoringAtom);

    allQuizQuestions.forEach((id) => {
      reset(quizQuestionsAtomFamily(id));
    });

    allScorings.forEach((id) => {
      reset(scoringAtomFamily(id));
    });

    reset(scoringAtom);
    reset(quizQuestionsAtom);
    reset(answeredQuestionsAtom);
  },
});
