import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Underline from 'components/Underline';
import BackButton from 'components/BackButton';

import { withPlayersReady } from 'atoms/players';
import { withQuestionsReady } from 'atoms/questions';
import { withBuzzersReady } from 'atoms/buzzers';

import styles from './Setup.module.css';
import ConfirmButton from 'components/ConfirmButton';

const Setup = () => {
  const navigate = useNavigate();
  const arePlayersReady = useRecoilValue(withPlayersReady);
  const areQuestionsReady = useRecoilValue(withQuestionsReady);
  const areBuzzersReady = useRecoilValue(withBuzzersReady);

  const navigateTo = (location) => {
    navigate(location);
  };

  return (
    <main className={styles.container}>
      <BackButton className={styles.back} onClick={() => navigate('/')} />

      <header>
        <h1>Game Setup</h1>
      </header>

      <section className={styles.intro}>
        <h2>
          <Underline
            width={130}
            height={10}
            color="#009ee3"
            className={styles.underline}
          />
          Hey there, Quiz Master!
          <br /> Let's make this quiz a hit!
        </h2>
      </section>
      <section className={styles.instructions}>
        <h3>
          Alrighty, quiz master! Before we get started with the fun, let's make
          sure you complete some important setup tasks.
        </h3>
      </section>
      <div className={styles.tasks}>
        <ConfirmButton
          ready={arePlayersReady}
          text="Setup players"
          onClick={() => navigateTo('players')}
          className={styles.button}
        />

        <ConfirmButton
          ready={areQuestionsReady}
          text="Setup questions"
          onClick={() => navigateTo('questions')}
          className={styles.button}
        />

        <ConfirmButton
          ready={areBuzzersReady}
          disabled={!arePlayersReady}
          text="Setup buzzers"
          onClick={() => navigateTo('buzzers')}
          className={styles.button}
        />
      </div>
    </main>
  );
};

export default Setup;
