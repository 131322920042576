import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Menu, MenuItem } from 'components/Menu';
import { Bin, Edit } from 'components/Icons';
import MoreButton from 'components/MoreButton';
import Handle from 'components/Handle';

import { questionsAtom, questionsAtomFamily } from 'atoms/questions';

import styles from './Question.module.css';

export const Question = ({ id, sortable }) => {
  const navigate = useNavigate();
  const setQuestions = useSetRecoilState(questionsAtom);
  const { question } = useRecoilValue(questionsAtomFamily(id));

  const removeQuestion = () => {
    setQuestions((currentQuestions) =>
      currentQuestions.filter((currentId) => currentId !== id)
    );
  };

  const editQuestion = () => {
    navigate(id);
  };

  const {
    attributes,
    listeners,
    setNodeRef,
    isDragging,
    transform,
    transition,
  } = useSortable({
    id,
    transition: {
      duration: 300, // milliseconds
      easing: 'cubic-bezier(0.4, 1, 0.5, 1)',
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <article
      ref={setNodeRef}
      style={style}
      className={
        isDragging ? `${styles.question} ${styles.isDragging}` : styles.question
      }
    >
      <h3>{question}</h3>

      {sortable ? (
        <Handle {...listeners} {...attributes} isDragging={isDragging} />
      ) : (
        <Menu align="end" renderMenuButton={() => <MoreButton />}>
          <MenuItem className={styles.danger} onClick={removeQuestion}>
            <Bin />
            <span className={styles.menutext}>Remove</span>
          </MenuItem>

          <MenuItem onClick={editQuestion}>
            <Edit />
            <span className={styles.menutext}>Edit question</span>
          </MenuItem>
        </Menu>
      )}
    </article>
  );
};
