import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { scoringAtom, scoringAtomFamily } from 'atoms/scoring';
import { quizQuestionsAtomFamily } from 'atoms/quiz';
import {
  questionsAtomFamily,
  withIsCurrentQuestionsMultiCheck,
} from 'atoms/questions';
import { playersAtom } from 'atoms/players';

const questionDuration = 20000;
const correctAnswerScore = 1000;
const maxTimeBonus = 500;
const allQuestionsCorrectBonus = 500;

const colors = ['blue', 'orange', 'green', 'yellow'];

export default function useQuizScoring(questionId) {
  const allPlayers = useRecoilValue(playersAtom);
  const [allScorings, updateAllScorings] = useRecoilState(scoringAtom);
  const updateCurrentScoring = useSetRecoilState(scoringAtomFamily(questionId));
  const question = useRecoilValue(questionsAtomFamily(questionId));
  const quizAnswers = useRecoilValue(quizQuestionsAtomFamily(questionId));
  const isMultiCheck = useRecoilValue(
    withIsCurrentQuestionsMultiCheck(questionId)
  );

  // set once when question finished
  useEffect(() => {
    if (!questionId) {
      return;
    }

    if (!allScorings.includes(questionId)) {
      updateAllScorings([...allScorings, questionId]);
    }

    const playersAnswers = quizAnswers.answers || {};
    const startTimeStamp = quizAnswers.start;

    const correctQuestionAnswers = colors
      .map((color, index) => {
        const answer = question.answers[index];
        return { color, correct: answer.correct, text: answer.text };
      })
      .filter(({ correct }) => correct === true);

    const calculateBonus = (buzzerTimeStamp) => {
      const difference = buzzerTimeStamp - startTimeStamp;
      return maxTimeBonus - (difference * maxTimeBonus) / questionDuration;
    };

    const scorings = allPlayers.map((playerId) => {
      const playerAnswers = playersAnswers[playerId] || {};
      const markedAsCorrectPlayerAnswers = Object.keys(playerAnswers)
        .map((color) => ({
          color,
          correct: playerAnswers[color].active,
          timeStamp: playerAnswers[color].timeStamp,
        }))
        .filter(({ correct }) => correct === true);

      const anyAnswerIsCorrect = correctQuestionAnswers.some(
        (correctAnswer) => {
          return (
            markedAsCorrectPlayerAnswers.find(
              (playerAnswer) => playerAnswer.color === correctAnswer.color
            ) != null
          );
        }
      );

      const anyMulticheckAnswerWrong =
        isMultiCheck &&
        markedAsCorrectPlayerAnswers.some((playerAnswer) => {
          return (
            correctQuestionAnswers.some(
              (correctAnswer) => correctAnswer.color === playerAnswer.color
            ) === false
          );
        });

      const allMulticheckAnswersAreCorrect =
        isMultiCheck &&
        correctQuestionAnswers.every((correctAnswer) => {
          return (
            markedAsCorrectPlayerAnswers.find(
              (playerAnswer) => playerAnswer.color === correctAnswer.color
            ) != null
          );
        });

      const singleCheckTimeBonus =
        (!isMultiCheck &&
          anyAnswerIsCorrect &&
          markedAsCorrectPlayerAnswers.reduce((_, current) => {
            return calculateBonus(current.timeStamp);
          }, 0)) ||
        0;

      // only the last buzzer counts for time bonus (all answers must be correct as well)
      const multiCheckTimeBonus =
        (isMultiCheck &&
          !anyMulticheckAnswerWrong &&
          allMulticheckAnswersAreCorrect &&
          markedAsCorrectPlayerAnswers.reduce((prev, current) => {
            const bonus = calculateBonus(current.timeStamp);
            return prev < bonus ? prev : bonus;
          }, maxTimeBonus)) ||
        0;

      const timeBonus = isMultiCheck
        ? multiCheckTimeBonus
        : singleCheckTimeBonus;

      const multiCheckBonus =
        isMultiCheck &&
        !anyMulticheckAnswerWrong &&
        allMulticheckAnswersAreCorrect
          ? allQuestionsCorrectBonus
          : 0;

      const hasCorrectAnswer = isMultiCheck
        ? anyAnswerIsCorrect && !anyMulticheckAnswerWrong
        : anyAnswerIsCorrect;

      const scoring =
        (hasCorrectAnswer ? correctAnswerScore : 0) +
        timeBonus +
        multiCheckBonus;

      return { player: playerId, scoring };
    });

    updateCurrentScoring({
      id: questionId,
      scorings,
    });
  }, [questionId]);
}
