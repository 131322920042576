const buttons = ['red', 'yellow', 'green', 'orange', 'blue'];

export function withRedButtonActive(buzzer) {
  if (!buzzer) {
    return false;
  }

  return buzzer.buttons.red === true;
}

export function withoutRedButtonActive(buzzer) {
  if (!buzzer) {
    return false;
  }

  return (
    buzzer.buttons.yellow === true ||
    buzzer.buttons.blue === true ||
    buzzer.buttons.orange === true ||
    buzzer.buttons.green === true
  );
}

export function getActiveButtons(buzzer) {
  if (!buzzer?.buttons) {
    return null;
  }

  return buttons
    .filter((color) => buzzer.buttons[color] === true)
    .map((color) => color);
}
