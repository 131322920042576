import useGamepads from './useGamepads';

import { mapGamepadToBuzzers } from 'util/gamepad';

export default function useBuzzers(filter) {
  const gamepads = useGamepads();
  const buzzers = gamepads.flatMap(mapGamepadToBuzzers);

  if (!filter) {
    return buzzers;
  }

  return buzzers.filter(filter);
}
