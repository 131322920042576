import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import useBuzzers from 'hooks/useBuzzers';
import { quizQuestionsAtom, quizQuestionsAtomFamily } from 'atoms/quiz';
import { withIsCurrentQuestionsMultiCheck } from 'atoms/questions';
import { withAllBuzzers } from 'atoms/buzzers';
import { withoutRedButtonActive, getActiveButtons } from 'util/buzzer';

export default function useQuizQuestion(questionId) {
  const activeBuzzers = useBuzzers(withoutRedButtonActive);
  const allRegisteredBuzzers = useRecoilValue(withAllBuzzers);
  const isMultiCheck = useRecoilValue(
    withIsCurrentQuestionsMultiCheck(questionId)
  );
  const [allQuestions, updateQuestions] = useRecoilState(quizQuestionsAtom);
  const updateCurrentQuestion = useSetRecoilState(
    quizQuestionsAtomFamily(questionId)
  );

  // set once when new question starts
  useEffect(() => {
    if (!questionId) {
      return;
    }

    if (!allQuestions.includes(questionId)) {
      updateQuestions([...allQuestions, questionId]);
    }

    updateCurrentQuestion({
      id: questionId,
      start: Date.now(),
    });
  }, [questionId]);

  // update when buzzer button clicked
  useEffect(() => {
    if (activeBuzzers.length < 1) {
      return;
    }

    const timeStamp = Date.now();

    updateCurrentQuestion((currentQuestion) => {
      // get player id assigned to buzzer
      const activeBuzzersWithPlayer = activeBuzzers
        .filter(({ id }) =>
          allRegisteredBuzzers.map(({ id }) => id).includes(id)
        )
        .map((buzzer) => {
          const registeredBuzzer = allRegisteredBuzzers.find(
            (buzz) => buzz.id === buzzer.id
          );
          return { ...buzzer, player: registeredBuzzer.player };
        });

      const allPlayersAnswers = currentQuestion.answers || {};

      const newAnswers = activeBuzzersWithPlayer
        .map((buzzer) => {
          const activeButtons = getActiveButtons(buzzer);

          const answers = isMultiCheck
            ? activeButtons.reduce((prev, color) => {
                const active = prev[color]?.active === true;
                return {
                  ...prev,
                  [color]: {
                    active: !active,
                    timeStamp,
                  },
                };
              }, allPlayersAnswers[buzzer.player] || {})
            : activeButtons.reduce(
                (prev, color) => ({
                  ...prev,
                  [color]: {
                    active: true,
                    timeStamp,
                  },
                }),
                {}
              );

          return { id: buzzer.player, answers: answers };
        })
        .reduce(
          (prev, current) => ({ ...prev, [current.id]: current.answers }),
          allPlayersAnswers
        );

      return {
        ...currentQuestion,
        answers: { ...allPlayersAnswers, ...newAnswers },
      };
    });
  }, [activeBuzzers]);
}
