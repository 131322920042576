import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import styles from "./Menu.module.css";

const Menu = ({
    renderMenuButton,
    width = "auto",
    align = "center",
    children,
}) => {
    const style = {
        width: width === "auto" ? "auto" : `${width}px`,
    };

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                {renderMenuButton()}
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
                <DropdownMenu.Content
                    className={styles.content}
                    align={align}
                    sideOffset={5}
                >
                    <div style={style}>{children}</div>
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
};

export default Menu;
