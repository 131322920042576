import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import Splash from 'pages/Splash';

import Quiz from 'pages/Quiz';
import Board from 'pages/Quiz/Board';
import Podium from 'pages/Quiz/Podium';
import Result from 'pages/Quiz/Result';
import Question from 'pages/Quiz/Question';

import Setup from 'pages/Setup';
import Players from 'pages/Setup/Players';
import Questions from 'pages/Setup/Questions';
import Update from 'pages/Setup/Questions/Update';
import Import from 'pages/Setup/Questions/Import';
import Buzzers from 'pages/Setup/Buzzers';
import Registration from 'pages/Setup/Buzzers/Registration';

import './index.css';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route index element={<Splash />} />

      <Route path="quiz" element={<Quiz />} />
      <Route path="quiz/podium" element={<Podium />} />
      <Route path="quiz/board/:id" element={<Board />} />
      <Route path="quiz/results/:id" element={<Result />} />
      <Route path="quiz/questions/:id" element={<Question />} />

      <Route path="setup" element={<Setup />} />
      <Route path="setup/players" element={<Players />} />
      <Route path="setup/questions" element={<Questions />} />
      <Route path="setup/questions/:id" element={<Update />} />
      <Route path="setup/questions/import" element={<Import />} />
      <Route path="setup/buzzers" element={<Buzzers />} />
      <Route path="setup/buzzers/registration" element={<Registration />} />
    </>
  )
);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RecoilRoot>
      <RouterProvider router={router} />
    </RecoilRoot>
  </React.StrictMode>
);
