import { atom, atomFamily, selector } from 'recoil';

export const scoringAtom = atom({
  key: 'scoring.questions',
  default: [],
});

export const scoringAtomFamily = atomFamily({
  key: 'scoring.questionsFamily',
  default: null,
});

export const withAllScorings = selector({
  key: 'questions.withAllScorings',
  get: ({ get }) => {
    const scorings = get(scoringAtom);
    return scorings.map((id) => {
      return get(scoringAtomFamily(id));
    });
  },
});
