import React from 'react';

import LinkButton from 'components/LinkButton';
import styles from './MorePlayers.module.css';

const MorePlayers = ({ count, onClick }) => {
  return (
    <section className={styles.container}>
      {count} more {count == 1 ? 'player' : 'players'}.
      <LinkButton className={styles.button} onClick={onClick}>
        Show all
      </LinkButton>
    </section>
  );
};

export default MorePlayers;
