import React from 'react';

import styles from './LinkButton.module.css';

const LinkButton = ({ children, onClick, className }) => {
  const cx = className ? `${className} ${styles.button}` : styles.button;

  return (
    <button className={cx} onClick={onClick}>
      {children}
    </button>
  );
};

export default LinkButton;
