const sortByTotalAscending = (a, b) =>
  a.total > b.total ? -1 : a.total < b.total ? 1 : 0;

export const getTotalPlayersScorings = (scorings) => {
  const playersScores = scorings
    .flatMap((scoring) => scoring.scorings)
    .reduce((prev, current) => {
      const player = current.player;
      const currentTotal = prev[player] || 0;
      const newTotal = currentTotal + current.scoring;
      return { ...prev, [player]: newTotal };
    }, {});

  return Object.keys(playersScores)
    .map((id) => {
      return { id, total: playersScores[id] };
    })
    .sort(sortByTotalAscending)
    .map((scoring) => ({ ...scoring, total: Math.floor(scoring.total) }));
};
