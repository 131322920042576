import { Check, Warning } from 'components/Icons';
import styles from './Validation.module.css';

const Validation = ({ valid, visible }) => {
  const cx = visible
    ? `${styles.validaton} ${styles.visible}`
    : styles.validaton;

  const colorClass = valid ? styles.valid : styles.invalid;

  return (
    <>
      {valid === false && (
        <div className={styles.hint}>
          Validation failed. Check console for more information
        </div>
      )}

      <div className={cx}>
        <div className={colorClass}>
          {valid ? <Check size={35} /> : <Warning size={30} />}
        </div>
      </div>
    </>
  );
};

export default Validation;
