import { useState, useEffect, useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

import debounce from 'util/debounce';
import validate from './validate';

import Textarea from 'components/Textarea';
import CloseButton from 'components/CloseButton';
import DownloadLink from './DownloadLink';
import Validation from './Validation';

import styles from './Import.module.css';
import Button from 'components/Button';

import useBeforeUnload from 'hooks/useBeforeUnload';
import { withAllQuestions } from 'atoms/questions';

const Import = () => {
  useBeforeUnload();
  const [json, setJson] = useState('');
  const [valid, setValid] = useState(null);
  const setQuestions = useSetRecoilState(withAllQuestions);
  const navigate = useNavigate();

  useEffect(() => {
    if (!json) {
      setValid(null);
    } else {
      verify(json);
    }
  }, [json]);

  const verify = useCallback(
    debounce((data) => {
      const { valid, errors } = validate(data);
      setValid(valid);
      !valid &&
        console.warn(
          '%cJSON Validation Errors:',
          'color: black; background: #ffee00'
        );
      !valid && console.warn(errors);
    }, 200),
    []
  );

  const onImportClick = () => {
    if (!valid) {
      return;
    }
    const questions = JSON.parse(json.trim());
    setQuestions(questions);
    navigate('/setup');
  };

  return (
    <main className={styles.container}>
      <CloseButton className={styles.close} onClick={() => navigate(-1)} />

      <header>
        <h1>Import Questions</h1>
      </header>

      <section className={styles.intro}>
        Import your existing questions.
      </section>

      <section className={styles.instruction}>
        <p>Please make sure that your questions are in correct JSON format.</p>

        <p>
          You don't have a file with questions yet? No worries, we prepared a
          template to help you get started. <DownloadLink />
        </p>
      </section>

      <section className={styles.import}>
        <Textarea
          value={json}
          placeholder="Paste the JSON content here..."
          onChange={setJson}
          autoFocus
        />
        <Validation valid={valid} visible={valid != null} />

        <Button
          className={styles.button}
          disabled={!valid}
          onClick={onImportClick}
        >
          Import
        </Button>
      </section>
    </main>
  );
};

export default Import;
