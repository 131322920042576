import { useResetRecoilState, useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';

import Winner from 'components/Winner';
import CloseButton from 'components/CloseButton';
import useWinners from 'hooks/useWinners';
import { questionsAtom } from 'atoms/questions';
import { withResetAll } from 'atoms/common';

import styles from './Podium.module.css';

const Podium = () => {
  const winners = useWinners();
  const questions = useRecoilValue(questionsAtom);
  const reset = useResetRecoilState(withResetAll);
  const navigate = useNavigate();

  const exit = () => {
    reset();
    navigate('/');
  };

  return (
    <main className={styles.container}>
      <CloseButton onClick={exit} className={styles.close} />
      <div className={styles.page}>
        <h1>And the winners are...</h1>

        <section className={styles.winners}>
          {winners.map((winner, index) => (
            <Winner
              key={winner.id}
              winner={winner}
              ranking={index + 1}
              answersCount={questions.length}
            />
          ))}
        </section>
      </div>
    </main>
  );
};

export default Podium;
