import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { withSetupReady } from 'atoms/common';
import ConfirmButton from 'components/ConfirmButton';

import styles from './Splash.module.css';
import FullscreenButton from 'components/FullscreenButton';

const Splash = () => {
  const navigate = useNavigate();
  const setupReady = useRecoilValue(withSetupReady);

  const start = () => {
    navigate('/quiz');
  };

  const setup = () => {
    navigate('/setup');
  };

  return (
    <main className={styles.container}>
      <FullscreenButton className={styles.fullscreen} />
      <div className={styles.page}>
        <header className={styles.intro}>
          <h1>Welcome to Bbc Buzzers</h1>
          <h3>
            Get ready to test your knowledge and have some fun with our exciting
            quiz game!
          </h3>
        </header>
        <div className={styles.button}>
          <ConfirmButton ready={setupReady} text="Setup game" onClick={setup} />
        </div>
        <div className={styles.button}>
          <ConfirmButton
            onClick={start}
            text="Start new quiz"
            disabled={!setupReady}
          />
        </div>
      </div>
    </main>
  );
};

export default Splash;
