import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';

import useSetupScreen from 'hooks/useSetupScreen';
import useBeforeUnload from 'hooks/useBeforeUnload';

import BackButton from 'components/BackButton';
import EmptyState from './EmptyState';
import Players from './Players';

import { withPlayersWithBuzzers } from 'atoms/buzzers';

import styles from './Buzzers.module.css';

const Buzzers = () => {
  useSetupScreen();
  useBeforeUnload();
  const navigate = useNavigate();
  const playersWithBuzzers = useRecoilValue(withPlayersWithBuzzers);

  const cx =
    playersWithBuzzers.length > 0
      ? `${styles.container} ${styles.players}`
      : styles.container;

  return (
    <main className={cx}>
      <BackButton className={styles.back} onClick={() => navigate(-1)} />

      <h1>Get ready to buzz!</h1>

      {playersWithBuzzers.length < 1 && (
        <EmptyState
          onClick={() => {
            navigate('registration');
          }}
        />
      )}

      {playersWithBuzzers.length > 0 && <Players />}
    </main>
  );
};

export default Buzzers;
