import styles from './Batch.module.css';

const Batch = ({ color }) => {
  const style = { ['--batch-color']: `var(--${color})` };

  return (
    <span style={style} className={styles.batch}>
      &nbsp;{color}&nbsp;
    </span>
  );
};

export default Batch;
