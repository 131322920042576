import React from 'react';

const Check = ({ size, strokeWidth }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        d="m7 12.456 3.206 3.258 7.251-7.598"
      />
    </svg>
  );
};

export default Check;

Check.defaultProps = {
  size: '24px',
  strokeWidth: '2.5px',
};
