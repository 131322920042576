import { useEffect, useState } from 'react';
import { Check } from '../Icons';

import styles from './Tracker.module.css';

const Tracker = ({ tracking, onAnimationEnd }) => {
  const [blocked, setIsBlocked] = useState(false);

  const cx = tracking
    ? `${styles.container} ${styles.tracking}`
    : `${styles.container} ${styles.found}`;

  useEffect(() => {
    if (!tracking && !blocked) {
      setIsBlocked(true);
      setTimeout(handleOnAnimationEnd, 900);
    }
  }, [tracking, handleOnAnimationEnd]);

  const handleOnAnimationEnd = () => {
    onAnimationEnd();
    setIsBlocked(false);
  };

  return (
    <div className={cx}>
      <Check size={70} />
    </div>
  );
};

export default Tracker;

Tracker.defaultProps = {
  tracking: true,
  onAnimationEnd: () => {},
};
