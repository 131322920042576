import React from 'react';

const Close = ({ height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      fill="none"
      viewBox="0 96 960 960"
    >
      <path
        fill="currentColor"
        d="M480 637.74 282.203 835.537q-12.87 12.869-30.87 12.869t-30.87-12.869q-12.869-12.87-12.869-30.87t12.869-30.87L418.26 576 220.463 378.203q-12.869-12.87-12.869-30.87t12.869-30.87q12.87-12.869 30.87-12.869t30.87 12.869L480 514.26l197.797-197.797q12.87-12.869 30.87-12.869t30.87 12.869q12.869 12.87 12.869 30.87t-12.869 30.87L541.74 576l197.797 197.797q12.869 12.87 12.869 30.87t-12.869 30.87q-12.87 12.869-30.87 12.869t-30.87-12.869L480 637.74Z"
      />
    </svg>
  );
};

export default Close;

Close.defaultProps = {
  height: '24px',
};
