import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import useBeforeUnload from 'hooks/useBeforeUnload';
import {
  playersAtom,
  playersAvatarAtom,
  playersAtomFamily,
} from 'atoms/players';

import Player from './Player';
import PlayerBox from './PlayerBox';
import BackButton from 'components/BackButton';

import styles from './Players.module.css';
import MorePlayers from './MorePlayers';

const Players = () => {
  useBeforeUnload();
  const navigate = useNavigate();
  const [showAll, setShowAll] = useState(false);
  const scrollToRef = React.useRef(null);

  const players = useRecoilValue(playersAtom);
  const avatars = useRecoilValue(playersAvatarAtom);

  const minPlayersCount = showAll ? players.count : 3;
  const otherPlayersCount = players.length - minPlayersCount;

  const addPlayer = useRecoilCallback(({ set }) => (name) => {
    const id = uuid();
    const avatar = avatars[Math.floor(Math.random() * avatars.length)];
    set(playersAtom, (allPlayers) => [...allPlayers, id]);
    set(playersAtomFamily(id), { id, name, avatar });
    set(
      playersAvatarAtom,
      avatars.filter((current) => current !== avatar)
    );
    scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
  });

  return (
    <main className={styles.container}>
      <BackButton className={styles.back} onClick={() => navigate(-1)} />

      <h1>Who is playing?</h1>

      {!showAll && otherPlayersCount > 0 && (
        <MorePlayers
          count={otherPlayersCount}
          onClick={() => setShowAll(true)}
        />
      )}

      <section className={styles.players}>
        {players.slice(-minPlayersCount).map((id) => (
          <Player key={id} id={id} />
        ))}
      </section>

      {avatars.length > 0 && (
        <>
          <div className={styles.input}>
            <h3>Add a new player</h3>
            <PlayerBox onSave={addPlayer}></PlayerBox>
          </div>
          <div style={{ marginTop: 40 }} ref={scrollToRef}></div>
        </>
      )}
    </main>
  );
};

export default Players;
