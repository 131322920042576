import styles from './Input.module.css';

const Input = ({
  value,
  color,
  placeholder,
  onChange,
  className,
  onEnter,
  max,
  autoFocus = true,
  ...props
}) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onEnter && onEnter();
    }
  };

  const handleChange = (event) => {
    if (max == null || event.target.value.length <= max) {
      onChange(event);
    }
  };

  const colorStyle = color
    ? { ['--input-color']: `var(--${color})` }
    : { ['--input-color']: 'var(--gray)' };

  let cx = className ? `${className} ${styles.input}` : styles.input;
  cx = (value?.length || 0) > 0 && color ? `${cx} ${styles.fill}` : cx;

  return (
    <div className={styles.box}>
      <input
        autoFocus={autoFocus}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        className={cx}
        style={colorStyle}
        {...props}
      />
      {max && <span className={styles.max}>{max - (value?.length || 0)}</span>}
    </div>
  );
};

export default Input;
