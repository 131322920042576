import React from 'react';

import { Close } from 'components/Icons';
import styles from './CloseButton.module.css';

const CloseButton = ({ onClick, className }) => {
  const cx = className ? `${className} ${styles.button}` : styles.button;

  return (
    <button onClick={onClick} className={cx}>
      <Close height={40} />
    </button>
  );
};

export default CloseButton;
