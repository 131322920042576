const keys = ['red', 'yellow', 'green', 'orange', 'blue'];

export function mapGamepadToBuzzers(gamepad) {
  if (!gamepad) {
    return {};
  }

  const { id, index, connected, buttons } = gamepad;

  const buzzers = buttons.reduce((prev, current, index) => {
    const buttonIndex = index % 5;

    if (buttonIndex === 0) {
      prev = [...prev, []];
    }

    const buzzer = prev[prev.length - 1];
    const key = keys[buttonIndex];
    buzzer[key] = current.pressed;

    return prev;
  }, []);

  return buzzers.map((buttons, buzzerIndex) => ({
    buttons,
    gamepad: id,
    connected,
    id: `${index}:${buzzerIndex}`,
  }));
}
