import React from 'react';

import styles from './Button.module.css';

const Button = ({
  onClick,
  primary = false,
  text = false,
  children,
  className,
  ...props
}) => {
  let cx = primary ? `${styles.button} ${styles.primary}` : styles.button;
  cx = text ? `${cx} ${styles.text}` : cx;
  cx = className ? `${cx} ${className}` : cx;

  return (
    <button onClick={onClick} className={cx} {...props}>
      {children}
    </button>
  );
};

export default Button;
