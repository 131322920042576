import React from "react";

const Edit = ({ height }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={height}
            fill="none"
            viewBox="0 96 960 960"
        >
            <path
                fill="currentColor"
                d="M787.464 413.151 644.429 270.702l45.55-45.789q19.797-19.797 48.92-19.623 29.123.174 49.717 20.29l44.623 44.383q20.594 20.276 20.29 48.852-.304 28.576-20.101 48.373l-45.964 45.963Zm-47.094 47.427-478.61 478.61H118.645V796.153l478.451-478.451L740.37 460.578Z"
            />
        </svg>
    );
};

export default Edit;

Edit.defaultProps = {
    height: "24px",
};
