import { useState, useEffect, useRef } from 'react';

export default function useGamepads() {
  const [gamepads, setGamepads] = useState([]);
  const gamepadsRef = useRef([]);
  const requestRef = useRef();

  const scanGamepads = () => {
    // Grab gamepads from browser API
    var detectedGamepads = navigator.getGamepads
      ? navigator.getGamepads()
      : navigator.webkitGetGamepads
      ? navigator.webkitGetGamepads()
      : [];

    const hasChanged = Object.keys(gamepadsRef.current).reduce(
      (prev, current) =>
        prev || gamepadsRef.current[current] !== detectedGamepads[current],
      false
    );

    if (hasChanged) {
      setGamepads(
        Object.values(detectedGamepads).filter((gamepad) => gamepad != null)
      );
    }

    gamepadsRef.current = detectedGamepads;
  };

  // Update each gamepad's status on each "tick"
  const animate = () => {
    scanGamepads();
    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  });

  if (window.debugBuzzers === true) {
    console.debug(gamepads);
  }

  return gamepads;
}
