import React from 'react';

const Download = ({ height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      fill="none"
      viewBox="0 96 960 960"
    >
      <path
        fill="currentColor"
        d="M479.804 715.173q-7.5 0-14.45-2.873-6.95-2.874-13.137-8.953L294.999 546.129q-11.826-11.826-11.326-28.283t12.326-28.283q11.827-11.826 28.001-11.826t28 11.826l88.391 89.391V256.042q0-16.636 11.501-28.405 11.502-11.77 28.283-11.77 16.782 0 28.108 11.77 11.326 11.769 11.326 28.405v322.912L609 489.563q11.826-11.826 28-11.826t28.001 11.826q11.826 11.826 11.826 28.283t-11.826 28.283L507.783 703.347q-6.225 6.079-13.352 8.953-7.128 2.873-14.627 2.873ZM211.521 923.132q-32.507 0-55.862-23.605-23.356-23.605-23.356-56.461V714.521q0-16.706 11.502-28.158 11.501-11.451 28.283-11.451 16.781 0 28.107 11.451 11.326 11.452 11.326 28.1v128.885h536.392V714.463q0-16.648 11.502-28.1 11.502-11.451 28.283-11.451t28.108 11.451q11.326 11.452 11.326 28.1v128.885q0 32.74-23.653 56.262-23.652 23.522-55.566 23.522H211.521Z"
      />
    </svg>
  );
};

export default Download;

Download.defaultProps = {
  height: '24px',
};
