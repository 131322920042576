import React from 'react';

const ExitFullscreen = ({ height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      fill="none"
      viewBox="0 96 960 960"
    >
      <path
        fill="currentColor"
        d="M333 856V723H200v-60h193v193h-60Zm234 0V663h193v60H627v133h-60ZM200 489v-60h133V296h60v193H200Zm367 0V296h60v133h133v60H567Z"
      />
    </svg>
  );
};

export default ExitFullscreen;

ExitFullscreen.defaultProps = {
  height: '24px',
};
