import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import styles from './MenuItem.module.css';

const MenuItem = ({ children, className, ...props }) => {
  const cx = className ? `${styles.item} ${className}` : styles.item;

  return (
    <DropdownMenu.Item className={cx} {...props}>
      {children}
    </DropdownMenu.Item>
  );
};

export default MenuItem;
