import React from 'react';

const Warning = ({ height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      fill="none"
      viewBox="0 96 960 960"
    >
      <path
        fill="currentColor"
        d="M97.666 936q-9.666 0-17.101-4.583Q73.13 926.833 69 919.333q-4.445-7.4-4.89-16.033-.444-8.633 4.89-17.3l382-660q5.333-8.667 12.833-12.667t16.167-4q8.667 0 16.167 4 7.5 4 12.833 12.667l382 660q5.333 8.667 4.889 17.3-.444 8.633-4.889 16.033-4.13 7.5-11.565 12.084Q872 936 862.334 936H97.666Zm385.118-118q14.216 0 23.716-9.617 9.5-9.617 9.5-23.833 0-14.216-9.617-23.716-9.617-9.5-23.833-9.5-14.217 0-23.716 9.617-9.5 9.617-9.5 23.833 0 14.216 9.617 23.716 9.616 9.5 23.833 9.5Zm0-114q14.216 0 23.716-9.583 9.5-9.583 9.5-23.75V521.333q0-14.167-9.617-23.75T482.55 488q-14.217 0-23.716 9.583-9.5 9.583-9.5 23.75v149.334q0 14.167 9.617 23.75Q468.567 704 482.784 704Z"
      />
    </svg>
  );
};

export default Warning;

Warning.defaultProps = {
  height: '24px',
};
