import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';

import Batch from 'components/Batch';

import useBeforeUnload from 'hooks/useBeforeUnload';
import useSetupScreen from 'hooks/useSetupScreen';
import ReadySet from 'components/ReadySet';

import { withNextQuestion } from 'atoms/questions';

import styles from './Quiz.module.css';

const Quiz = () => {
  useSetupScreen();
  useBeforeUnload();
  const navigate = useNavigate();
  const nextQuestion = useRecoilValue(withNextQuestion);

  useEffect(() => {
    if (!nextQuestion) {
      navigate('/quiz/podium');
    }
  }, [nextQuestion]);

  const redirect = () => {
    if (nextQuestion) {
      navigate('questions/' + nextQuestion);
    }
  };

  // Ready, set, quiz!
  return (
    <main className={styles.container}>
      <ReadySet texts={['ready', 'set', 'quiz!']} onAnimationEnd={redirect} />
      <p className={styles.instruction}>
        Use your <Batch color="blue" />, <Batch color="orange" />,{' '}
        <Batch color="green" /> or <Batch color="yellow" />
        <br />
        buzzer button(s) to answer the question
      </p>
    </main>
  );
};

export default Quiz;
