import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import Button from 'components/Button';
import Input from 'components/Input';
import Checkbox from 'components/Checkbox';

import { setProperty } from 'util/common';
import { questionsAtom, questionsAtomFamily } from 'atoms/questions';
import useBeforeUnload from 'hooks/useBeforeUnload';

import styles from './Update.module.css';

const colors = ['blue', 'orange', 'green', 'yellow'];

const defaultValues = {
  question: '',
  ...colors.reduce(
    (acc, current) => ({
      ...acc,
      [current]: { text: '', correct: false },
    }),
    {}
  ),
};

const Update = () => {
  useBeforeUnload();
  const navigate = useNavigate();
  const [id, setId] = useState();
  const { id: param } = useParams();
  const [values, setValues] = useState(defaultValues);
  const [savedQuestion, saveQuestion] = useRecoilState(questionsAtomFamily(id));
  const [questionsIds, setQuestionsIds] = useRecoilState(questionsAtom);

  useEffect(() => {
    if (param == null) {
      return;
    }
    setId(param === 'new' ? uuid() : param);
  }, [param]);

  useEffect(() => {
    if (!savedQuestion) {
      return;
    }

    setValues({
      question: savedQuestion.question,
      ...colors.reduce(
        (acc, current, index) => ({
          ...acc,
          [current]: savedQuestion.answers[index],
        }),
        {}
      ),
    });
  }, [id, savedQuestion]);

  const onAnswerUpdate = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const updatedQuestion = setProperty(values, `${name}.text`, value);
    setValues(updatedQuestion);
  };

  const onCheckboxUpdate = (event) => {
    const name = event.target.name;
    const checked = event.target.checked;
    const updatedQuestion = setProperty(values, `${name}.correct`, checked);
    setValues(updatedQuestion);
  };

  const answersAreValid =
    values.question?.length > 0 &&
    colors.some((color) => values[color]?.correct) &&
    colors.every((color) => values[color]?.text?.length > 0);

  const submit = () => {
    if (!answersAreValid) {
      return;
    }

    const question = {
      id,
      question: values.question,
      answers: colors.map((color) => ({
        text: values[color].text,
        correct: values[color].correct,
      })),
    };

    saveQuestion(question);

    if (param === 'new') {
      setQuestionsIds([...questionsIds, question.id]);
      setId(uuid());
      setValues(defaultValues);
    } else {
      navigate(-1);
    }
  };

  return (
    <main className={styles.container}>
      <header>
        <h1>{param === 'new' ? 'Create' : 'Edit'} question</h1>
      </header>

      <p className={styles.intro}>
        Please enter a question and four answers. Tick at least one correct
        answer.
      </p>

      <section className={styles.box}>
        <article>
          <Input
            placeholder="Start typing your question"
            value={values.question}
            onChange={(e) => setValues({ ...values, question: e.target.value })}
            max={120}
            autoFocus
          />
        </article>

        {colors.map((name) => (
          <article key={name}>
            <Input
              placeholder={`Add ${name} answer`}
              name={name}
              color={name}
              value={values[name]?.text || ''}
              onChange={onAnswerUpdate}
              max={80}
              autoFocus={false}
            />
            <Checkbox
              color={name}
              name={name}
              checked={values[name]?.correct}
              onChange={onCheckboxUpdate}
              className={styles.checkbox}
            />
          </article>
        ))}
        <section className={styles.buttons}>
          <Button text={true} onClick={() => navigate(-1)}>
            Cancel
          </Button>
          <Button disabled={!answersAreValid} onClick={submit}>
            {param === 'new' ? 'Next' : 'Save'}
          </Button>
        </section>
      </section>
    </main>
  );
};

export default Update;
