import { atom, atomFamily, selector } from 'recoil';

import { playersAtom } from './players';
import {
  questionsAtomFamily,
  withIsCurrentQuestionsMultiCheck,
} from './questions';

const colors = ['blue', 'orange', 'green', 'yellow'];

export const quizQuestionsAtom = atom({
  key: 'quiz.questions',
  default: [],
});

export const quizQuestionsAtomFamily = atomFamily({
  key: 'quiz.questionsFamily',
  default: null,
});

export const withPlayerAnswersCount = selector({
  key: 'quiz.withPlayerAnswersCount',
  get: ({ get }) => {
    const allPlayers = get(playersAtom);
    const allQuestions = get(quizQuestionsAtom);
    if (allQuestions.length < 1) {
      return [];
    }

    const questionId = allQuestions[allQuestions.length - 1];
    const quizQuestion = get(quizQuestionsAtomFamily(questionId));
    const isMultiCheck = get(withIsCurrentQuestionsMultiCheck(questionId));

    if (!quizQuestion?.answers) {
      return [];
    }

    return allPlayers.map((key) => {
      const answers = quizQuestion.answers[key];
      if (!answers) {
        return { id: key, count: 0 };
      }

      const count = Object.values(answers).reduce((prev, current) => {
        if (!isMultiCheck && prev > 0) {
          return 1;
        }
        return current?.active ? prev + 1 : prev;
      }, 0);
      return {
        id: key,
        count,
      };
    });
  },
});

export const withAnswersCount = selector({
  key: 'quiz.withAnswersCount',
  get: ({ get }) => {
    const defaultValues = colors.reduce(
      (prev, current) => ({ ...prev, [current]: 0 }),
      {}
    );

    const allQuestions = get(quizQuestionsAtom);
    if (allQuestions.length < 1) {
      return defaultValues;
    }

    const questionId = allQuestions[allQuestions.length - 1];
    const quizQuestion = get(quizQuestionsAtomFamily(questionId));
    if (!quizQuestion?.answers) {
      return defaultValues;
    }

    return Object.values(quizQuestion.answers).reduce((prev, current) => {
      const playerAnswers = Object.keys(current).filter(
        (key) => current[key].active
      );

      return playerAnswers.reduce((allAnswers, color) => {
        allAnswers[color] += 1;
        return allAnswers;
      }, prev);
    }, defaultValues);
  },
});

export const withCorrectAnswers = selector({
  key: 'quiz.withCorrectAnswers',
  get: ({ get }) => {
    const allPlayers = get(playersAtom);
    const allQuestions = get(quizQuestionsAtom);
    if (allQuestions.length < 1) {
      return [];
    }

    const defaultValue = allPlayers.reduce(
      (prev, playerId) => ({ ...prev, [playerId]: 0 }),
      {}
    );

    return allQuestions.reduce((prev, questionId) => {
      const question = get(questionsAtomFamily(questionId));
      const isMultiCheck = get(withIsCurrentQuestionsMultiCheck(questionId));
      const correctAnswers = colors.reduce(
        (result, color, index) =>
          question.answers[index].correct ? [...result, color] : result,
        []
      );
      const incorrectAnswers = colors.filter(
        (color) => !correctAnswers.includes(color)
      );

      const quizQuestion = get(quizQuestionsAtomFamily(questionId));
      allPlayers.forEach((playerId) => {
        const answers = quizQuestion.answers || {};
        const playersAnswers = answers[playerId] || {};
        const hasCorrectAnswer = Object.keys(playersAnswers)
          .filter((key) => playersAnswers[key].active)
          .reduce((result, currentAnswer) => {
            if (result === true) {
              return true;
            }
            return correctAnswers.some((color) => color === currentAnswer);
          }, false);
        const hasIncorrectAnswer =
          isMultiCheck &&
          incorrectAnswers.some((incorrect) =>
            Object.keys(playersAnswers).includes(incorrect)
          );
        if (hasCorrectAnswer && !hasIncorrectAnswer) {
          prev = { ...prev, [playerId]: prev[playerId] + 1 };
        }
      });

      return { ...prev };
    }, defaultValue);
  },
});
