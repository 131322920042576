import Avatar from 'components/Avatar';

import styles from './Winner.module.css';

const ordinalNumbers = ['st', 'nd', 'rd', 'th', 'th'];

const Winner = ({ winner, answersCount, ranking }) => {
  const { avatar, name, color, total, correctAnswers } = winner;

  const style = {
    ['--container-color']: color,
  };

  const cx = ranking === 1 ? `${styles.winner} ${styles.first}` : styles.winner;
  const ordinalNumber = ordinalNumbers[ranking - 1];
  const avatarSize = ranking === 1 ? 80 : 60;

  return (
    <article className={cx} style={style}>
      <div className={styles.left}>
        <div className={styles.ranking}>
          {ranking}
          <span className={styles.ordinal}>{ordinalNumber}</span>
        </div>
        <div className={styles.nameBox}>
          <div className={styles.name}>{name}</div>
          <div className={styles.total}>{total}</div>
        </div>
      </div>

      <div className={styles.right}>
        <div className={styles.answers}>
          {correctAnswers}
          <span className={styles.of}>of</span>
          {answersCount}
        </div>
        <div className={styles.avatar}>
          <Avatar size={avatarSize} code={avatar} round />
        </div>
      </div>
    </article>
  );
};

export default Winner;
