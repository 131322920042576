import React from 'react';

import { Bin } from 'components/Icons';

import styles from './RemoveButton.module.css';

const RemoveButton = ({ onClick, className }) => {
  const cx = className ? `${styles.button} ${className}` : styles.button;

  return (
    <button onClick={onClick} className={cx}>
      <Bin height={30} />
    </button>
  );
};

export default RemoveButton;
