import { atom, atomFamily, selector } from 'recoil';

import { withRemoveBuzzerByPlayer } from './buzzers';

export const playersAtom = atom({
  key: 'players.players',
  default: [],
});

export const playersAvatarAtom = atom({
  key: 'players.avatars',
  default: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22,
  ],
});

export const playersAvatarColorAtom = atom({
  key: 'players.avatarColors',
  default: {
    1: '#481260',
    2: '#027390',
    3: '#c7201c',
    4: '#526a1e',
    5: '#372114',
    6: '#501d0b',
    7: '#352b33',
    8: '#2a100a',
    9: '#371705',
    10: '#415768',
    11: '#281632',
    12: '#2f3012',
    13: '#29460c',
    14: '#45734b',
    15: '#1f285f',
    16: '#341d0f',
    17: '#80598e',
    18: '#5c7d02',
    19: '#713314',
    20: '#4f3548',
    21: '#472415',
    22: '#345370',
  },
});

export const playersAtomFamily = atomFamily({
  key: 'players.playersFamily',
  default: {},
});

export const withPlayersAndColors = selector({
  key: 'players.withPlayersAndColors',
  get: ({ get }) => {
    const players = get(playersAtom);
    const allColors = get(playersAvatarColorAtom);
    return players.reduce((prev, playerId) => {
      const player = get(playersAtomFamily(playerId));
      const color = allColors[player.avatar];
      return {
        ...prev,
        [playerId]: {
          name: player.name,
          avatar: player.avatar,
          color,
        },
      };
    }, {});
  },
});

export const withPlayersReady = selector({
  key: 'players.ready',
  get: ({ get }) => {
    const players = get(playersAtom);
    return players && players.length > 0;
  },
});

export const withRemovePlayer = selector({
  key: 'players.withRemovePlayer',
  get: () => {},
  set: ({ get, set }, id) => {
    set(withRemoveBuzzerByPlayer, id);

    const { avatar } = get(playersAtomFamily(id));
    set(playersAtom, (players) => players.filter((uuid) => uuid != id));
    set(playersAvatarAtom, (avatars) => [...avatars, avatar]);
    set(playersAtomFamily(id), null);
  },
});
