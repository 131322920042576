import React, { forwardRef } from 'react';

import { Handle as DragHandle } from 'components/Icons';

import styles from './Handle.module.css';

export const Handle = forwardRef((props, ref) => {
  const { isDragging, ...rest } = props;
  const cx = isDragging
    ? `${styles.handle} ${styles.isDragging}`
    : styles.handle;

  return (
    <button ref={ref} className={cx} tabIndex={0} {...rest}>
      <DragHandle height={28} />
    </button>
  );
});
