import { useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { Menu, MenuItem } from 'components/Menu';
import { Bin, Edit } from 'components/Icons';
import Avatar from 'components/Avatar';
import MoreButton from 'components/MoreButton';
import PlayerBox from './PlayerBox';

import { playersAtomFamily, withRemovePlayer } from 'atoms/players';

import styles from './Player.module.css';

const Player = ({ id }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [player, setPlayer] = useRecoilState(playersAtomFamily(id));

  const removePlayer = useSetRecoilState(withRemovePlayer);

  const updatePlayer = (name) => {
    if (name == null || name.length < 1) {
      setHasError(true);
      return;
    }
    setPlayer({ ...player, name });
    setIsEditing(false);
  };

  return (
    <article className={styles.player}>
      {isEditing && (
        <div className={styles.edit}>
          <h3>Update the name</h3>
          <PlayerBox value={player.name} onSave={updatePlayer} />
        </div>
      )}

      {!isEditing && (
        <>
          <div>
            <Avatar code={player.avatar} />
            <p>{player.name}</p>
          </div>

          <Menu width="180" align="end" renderMenuButton={() => <MoreButton />}>
            <MenuItem
              className={styles.danger}
              onClick={() => removePlayer(id)}
            >
              <Bin />
              <span className={styles.menutext}>Remove</span>
            </MenuItem>

            <MenuItem onClick={() => setIsEditing(true)}>
              <Edit />
              <span className={styles.menutext}>Edit name</span>
            </MenuItem>
          </Menu>
        </>
      )}
    </article>
  );
};

export default Player;
