import React from 'react';

const MultiCheck = ({ height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      fill="none"
      viewBox="0 96 960 960"
    >
      <path
        fill="currentColor"
        d="m490 673 228-228-42-41-185 186-97-97-42 42 138 138ZM260 856q-24 0-42-18t-18-42V236q0-24 18-42t42-18h560q24 0 42 18t18 42v560q0 24-18 42t-42 18H260ZM140 976q-24 0-42-18t-18-42V296h60v620h620v60H140Z"
      />
    </svg>
  );
};

export default MultiCheck;

MultiCheck.defaultProps = {
  height: '24px',
};
