import React from 'react';

const Back = ({ height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      fill="none"
      viewBox="0 96 960 960"
    >
      <path
        fill="currentColor"
        d="m336.087 794.87-197-196q-5.478-5.479-7.837-11.316-2.359-5.837-2.359-12.554 0-6.717 2.359-12.554 2.359-5.837 7.837-11.316l197-197q9.196-9.195 23.87-9.695 14.673-.5 24.869 9.695 10.196 10.196 10.196 24.37 0 14.174-10.196 24.37L246.761 540.935h564.196q14.673 0 24.369 9.695 9.696 9.696 9.696 24.37 0 14.674-9.696 24.37-9.696 9.695-24.369 9.695H246.761L384.826 747.13q9.196 9.196 9.576 23.37.381 14.174-9.576 24.37-10.196 10.195-24.369 10.195-14.174 0-24.37-10.195Z"
      />
    </svg>
  );
};

export default Back;

Back.defaultProps = {
  height: '24px',
};
