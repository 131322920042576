import React, { useState, useEffect } from 'react';

import Input from 'components/Input';
import Button from 'components/Button';

import styles from './PlayerBox.module.css';

const PlayerBox = ({ onSave, value, error = 'Name is required' }) => {
  const [name, setName] = useState('');
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (value) {
      setName(value);
    }
  }, [value]);

  const onClick = () => {
    if (!name) {
      setIsValid(false);
      return;
    }

    setName('');
    setIsValid(true);
    onSave(name);
  };

  return (
    <>
      <div className={styles.box}>
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          onEnter={onClick}
        />
        <Button className={styles.button} onClick={onClick}>
          Save
        </Button>
      </div>
      {!isValid && <div className={styles.error}>{error}</div>}
    </>
  );
};

export default PlayerBox;
