import React from "react";

const Bin = ({ height }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={height}
            fill="none"
            viewBox="0 96 960 960"
        >
            <path
                fill="currentColor"
                d="m480 644.761 86.942 87.609q11.971 10.971 27.468 10.971 15.496 0 26.626-10.971 11.305-11.464 11.305-27.214 0-15.75-11.305-26.214l-86.942-88.275 86.942-88.276q11.972-11.971 11.972-27.468 0-15.496-11.972-25.96-10.891-11.224-26.427-11.558-15.536-.333-27.427 11.558L480 536.572l-86.276-87.609q-10.463-11.971-26.213-11.637-15.75.333-27.881 11.637-10.891 10.384-10.891 26.174t10.891 27.681l86.943 87.849-86.943 87.609q-10.971 11.13-10.971 26.547 0 15.416 10.971 27.547 12.131 10.971 27.547 10.971 15.417 0 26.547-10.971L480 644.761ZM265.181 942.696q-30.986 0-53.449-22.384-22.464-22.384-22.464-53.37v-548.79h-7.334q-16.166 0-27.021-10.855t-10.855-27.022q0-16.167 10.855-27.022 10.855-10.855 27.021-10.855h165.761q0-16.166 10.855-27.101 10.855-10.935 27.022-10.935h188.856q16.167 0 27.101 10.935 10.935 10.935 10.935 27.101h165.602q16.166 0 27.021 10.855t10.855 27.022q0 16.167-10.855 27.022-10.855 10.855-27.021 10.855h-7.334v548.79q0 30.986-22.464 53.37-22.463 22.384-53.449 22.384H265.181Z"
            />
        </svg>
    );
};

export default Bin;

Bin.defaultProps = {
    height: "24px",
};
