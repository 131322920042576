import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import { withPlayersReady } from 'atoms/players'

export default function useSetupScreen() {
  const navigate = useNavigate()
  const isPlayersReady = useRecoilValue(withPlayersReady)

  useEffect(() => {
    if (!isPlayersReady) {
      navigate('/setup')
    }
  }, [isPlayersReady])
}
