import React from 'react';

import { Back } from 'components/Icons';

import styles from './BackButton.module.css';

const BackButton = ({ onClick, className }) => {
  const cx = className ? `${styles.button} ${className}` : styles.button;

  return (
    <button onClick={onClick} className={cx}>
      <Back height={40} />
    </button>
  );
};

export default BackButton;
