import React from 'react';
import { useRecoilValue } from 'recoil';

import Player from 'components/Player';
import { withPlayerAnswersCount } from 'atoms/quiz';

import styles from './Players.module.css';

const Players = () => {
  const playersAnswers = useRecoilValue(withPlayerAnswersCount);

  const players = playersAnswers.map(({ id, count }) => (
    <div key={id} className={styles.player}>
      {count < 1 ? null : (
        <Player id={id} round size={60} count={count} noName />
      )}
    </div>
  ));

  return <div className={styles.container}>{players}</div>;
};

export default Players;
