import { useRecoilValue } from 'recoil';

import { withQuestionsCount } from 'atoms/questions';

import styles from './QuestionsCount.module.css';

const QuestionsCount = () => {
  const count = useRecoilValue(withQuestionsCount);

  return (
    <div>
      <h3 className={styles.counter}>
        <span className={styles.highlight}>{count.answered + 1}</span> of{' '}
        <span className={styles.highlight}>{count.total}</span>
      </h3>
    </div>
  );
};

export default QuestionsCount;
