import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';

import MoreButton from 'components/MoreButton';
import { Download, List, Edit, Menu as MenuIcon } from 'components/Icons';
import { Menu, MenuItem } from 'components/Menu';

import { withAllQuestions } from 'atoms/questions';

import styles from './More.module.css';

export const More = ({ children, className }) => {
  return (
    <Menu
      align="end"
      renderMenuButton={() => (
        <MoreButton className={className}>
          <MenuIcon height={40} />
        </MoreButton>
      )}
    >
      {children}
    </Menu>
  );
};

export const MoreExport = () => {
  const questions = useRecoilValue(withAllQuestions);

  const exportData = () => {
    const ready4Export = questions.map(({ id, ...question }) => ({
      ...question,
    }));
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(ready4Export, null, 4)
    )}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = 'questions.json';
    link.click();
  };

  return (
    <MenuItem onClick={exportData}>
      <Download />
      <span className={styles.menutext}>Export questions</span>
    </MenuItem>
  );
};

export const MoreSortable = ({ active, toggle }) => {
  return (
    <MenuItem onClick={toggle}>
      {active ? <Edit /> : <List />}
      <span className={styles.menutext}>
        {active ? 'Edit' : 'Reorder'} questions
      </span>
    </MenuItem>
  );
};

export const MoreAdd = ({ active }) => {
  const navigate = useNavigate();

  const addQuestion = () => {
    navigate('new');
  };

  if (!active) {
    return null;
  }

  return (
    <MenuItem onClick={addQuestion}>
      <Edit />
      <span className={styles.menutext}>Add question</span>
    </MenuItem>
  );
};
