import React from 'react';

import Button from 'components/Button';
import { Check } from 'components/Icons';

import styles from './ConfirmButton.module.css';

const SetupButton = ({ ready, text, disabled, onClick, className }) => {
  const cx = className ? `${className} ${styles.button}` : styles.button;

  return (
    <div className={cx}>
      <Button disabled={disabled} onClick={onClick}>
        {text}
      </Button>
      {ready && (
        <div className={styles.check}>
          <Check size={50} strokeWidth={2.5} />
        </div>
      )}
    </div>
  );
};

export default SetupButton;
