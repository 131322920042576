import { useRecoilValue } from 'recoil';

import { withPlayersAndColors } from 'atoms/players';
import { withCorrectAnswers } from 'atoms/quiz';
import { withAllScorings } from 'atoms/scoring';
import { getTotalPlayersScorings } from 'util/scorings';

export default function useWinners() {
  const correctAnswers = useRecoilValue(withCorrectAnswers);
  const players = useRecoilValue(withPlayersAndColors);

  const allScorings = useRecoilValue(withAllScorings);
  const allScorers = getTotalPlayersScorings(allScorings);
  return allScorers
    .slice(0, allScorers.length > 5 ? 5 : allScorers.length)
    .map((scorer) => ({
      ...scorer,
      ...players[scorer.id],
      correctAnswers: correctAnswers[scorer.id] || 0,
    }));
}
