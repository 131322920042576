import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';

import {
  withPlayersWithBuzzers,
  withPlayersWithoutBuzzers,
  withRemoveBuzzerByPlayer,
} from 'atoms/buzzers';

import Player from 'components/Player';
import RemoveButton from 'components/RemoveButton';

import styles from './Players.module.css';

const Players = () => {
  const navigate = useNavigate();
  const playersWithBuzzers = useRecoilValue(withPlayersWithBuzzers);
  const playersWithoutBuzzers = useRecoilValue(withPlayersWithoutBuzzers);
  const removeBuzzerByPlayer = useSetRecoilState(withRemoveBuzzerByPlayer);

  const anyPlayersWithoutBuzzers = playersWithoutBuzzers.length > 0;

  return (
    <section className={styles.players}>
      {anyPlayersWithoutBuzzers && (
        <div className={styles.continue}>
          {playersWithoutBuzzers.length}{' '}
          {`${
            playersWithoutBuzzers.length === 1 ? 'player does' : 'players do'
          }`}{' '}
          not have a buzzer assigned yet.
          <Button
            onClick={() => {
              navigate('registration');
            }}
          >
            Continue with registration
          </Button>
        </div>
      )}

      <h3>Players with buzzers</h3>
      {playersWithBuzzers.map((id) => (
        <div key={id} className={styles.player}>
          <Player size={60} id={id} round />
          <RemoveButton onClick={() => removeBuzzerByPlayer(id)} />
        </div>
      ))}
    </section>
  );
};

export default Players;
