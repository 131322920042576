import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';

import styles from './EmptyState.module.css';

const EmptyState = () => {
  const navigate = useNavigate();

  const navigateTo = (location) => {
    navigate(location);
  };

  return (
    <section className={styles.container}>
      <p className={styles.intro}>
        It seems like you haven't already added any questions to your quiz.
        Don't worry, you're in the right place to start!
      </p>

      <section className={styles.articles}>
        <article>
          <p>
            To get started, simply click on the "Add Question" button to create
            your first question.
          </p>
          <p>You can add as many questions as you want.</p>
          <Button
            className={styles.button}
            onClick={() => {
              navigateTo('new');
            }}
          >
            Add Question
          </Button>
        </article>
        <article>
          <p>
            If you've already created questions for your quiz and don't want to
            start from scratch, we've got you covered.
          </p>
          <p>
            You can import your existing questions and add them to your quiz
            with just a few clicks!
          </p>
          <Button
            className={styles.button}
            onClick={() => {
              navigateTo('import');
            }}
          >
            Import
          </Button>
        </article>
      </section>
    </section>
  );
};

export default EmptyState;
