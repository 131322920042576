import { useRecoilValue } from 'recoil';

import Avatar from 'components/Avatar';
import { playersAtomFamily, playersAvatarColorAtom } from 'atoms/players';

import styles from './Player.module.css';

const Player = ({ size, id, round, noName, count, className }) => {
  const player = useRecoilValue(playersAtomFamily(id));
  const colors = useRecoilValue(playersAvatarColorAtom);

  const color = colors[player.avatar];

  const style = { ['--counter-background']: color };

  if (id == null) {
    return null;
  }

  const cx = className ? `${styles.player} ${className}` : styles.player;

  return (
    <div className={cx}>
      <Avatar size={size} code={player.avatar} round={round} />
      {count && count > 1 && (
        <div className={styles.count} style={style}>
          <div>{count}</div>
        </div>
      )}
      {!noName && <p>{player.name}</p>}
    </div>
  );
};

export default Player;
