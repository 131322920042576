import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate, useParams } from 'react-router-dom';

import Players from './Players';
import QuestionsCount from './QuestionsCount';
import QuestionWithAnswers from './QuestionWithAnswers';
import Timer from 'components/Timer';
import MultiCheckHint from 'components/MultiCheckHint';
import useBeforeUnload from 'hooks/useBeforeUnload';
import useAudio from 'hooks/useAudio';
import useQuizQuestion from 'hooks/useQuizQuestion';

import {
  questionsAtomFamily,
  withIsCurrentQuestionsMultiCheck,
} from 'atoms/questions';

import styles from './Question.module.css';

const Question = () => {
  useBeforeUnload();
  const navigate = useNavigate();
  const { id } = useParams();
  const { question, answers } = useRecoilValue(questionsAtomFamily(id));
  const isMultiCheck = useRecoilValue(withIsCurrentQuestionsMultiCheck(id));
  const waiting = useAudio('/sounds/waiting.mp3');
  const timeup = useAudio('/sounds/timeup.mp3');
  useQuizQuestion(id);

  useEffect(() => {
    waiting.play();
    // when navigating with the browser back button
    window.onpopstate = () => waiting.pause();
  }, []);

  const showResults = () => {
    waiting.pause();
    timeup.play();
    navigate('/quiz/results/' + id);
  };

  return (
    <main className={styles.container}>
      <section className={styles.quantity}>
        <QuestionsCount />
      </section>
      {isMultiCheck && (
        <section className={styles.hint}>
          <MultiCheckHint />
        </section>
      )}
      <section className={styles.counter}>
        <Timer onExpired={showResults} started={true} />
      </section>
      <section className={styles.question}>
        <QuestionWithAnswers question={question} answers={answers} />
      </section>
      <section className={styles.players}>
        <Players />
      </section>
    </main>
  );
};

export default Question;
