import React from 'react';

import styles from './Checkbox.module.css';

const Checkbox = ({ label, className, color, checked, onChange, ...props }) => {
  const cx = className ? `${styles.label} ${className}` : styles.label;

  const colorStyle = color
    ? { ['--ckeckbox-color']: `var(--${color})` }
    : { ['--ckeckbox-color']: 'var(--gray)' };

  return (
    <label className={cx} style={colorStyle}>
      <input
        className={styles.hidden}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        {...props}
      />

      <div className={styles.checkbox}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height={30}
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth={3}
            d="m7 12.456 3.206 3.258 7.251-7.598"
          />
        </svg>
      </div>

      {label && <span style={{ marginLeft: 8 }}>{label}</span>}
    </label>
  );
};

export default Checkbox;
