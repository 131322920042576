import React from 'react';

const List = ({ height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      fill="none"
      viewBox="0 96 960 960"
    >
      <path
        fill="currentColor"
        d="M153.333 710.378q-18.5 0-31.286-12.82-12.787-12.82-12.787-31.37t12.787-31.253q12.786-12.703 31.286-12.703h653.334q18.5 0 31.286 12.82 12.787 12.82 12.787 31.37t-12.787 31.253q-12.786 12.703-31.286 12.703H153.333Zm0 178.884q-18.5 0-31.286-12.82-12.787-12.82-12.787-31.746 0-18.55 12.787-31.253 12.786-12.703 31.286-12.703h653.334q18.5 0 31.286 12.82 12.787 12.82 12.787 31.37 0 18.926-12.787 31.629-12.786 12.703-31.286 12.703H153.333Zm0-357.494q-18.5 0-31.286-12.82-12.787-12.82-12.787-31.37 0-18.549 12.787-31.441 12.786-12.891 31.286-12.891h653.334q18.5 0 31.286 13.008 12.787 13.008 12.787 31.558t-12.787 31.253q-12.786 12.703-31.286 12.703H153.333Zm0-179.175q-18.5 0-31.286-12.82-12.787-12.82-12.787-31.746 0-18.55 12.787-31.253 12.786-12.703 31.286-12.703h653.334q18.5 0 31.286 12.82 12.787 12.82 12.787 31.37 0 18.926-12.787 31.629-12.786 12.703-31.286 12.703H153.333Z"
      />
    </svg>
  );
};

export default List;

List.defaultProps = {
  height: '24px',
};
