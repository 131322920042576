import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';

import useSetupScreen from 'hooks/useSetupScreen';
import useBeforeUnload from 'hooks/useBeforeUnload';
import useBuzzers from 'hooks/useBuzzers';
import { withRedButtonActive } from 'util/buzzer';

import Tracker from 'components/Tracker';
import Player from 'components/Player';
import CloseButton from 'components/CloseButton';

import {
  buzzersAtom,
  buzzersAtomFamily,
  withPlayersWithoutBuzzers,
} from 'atoms/buzzers';

import styles from './Registration.module.css';

const Registration = () => {
  useSetupScreen();
  useBeforeUnload();
  const navigate = useNavigate();
  const [playerId, setPlayerId] = useState();
  const [isTracking, setIsTracking] = useState(true);
  const [currentBuzzer, setCurrentBuzzer] = useState();
  const [registeredBuzzers, setRegisteredBuzzers] = useRecoilState(buzzersAtom);
  const activeBuzzers = useBuzzers(withRedButtonActive);
  const playersWithoutBuzzers = useRecoilValue(withPlayersWithoutBuzzers);
  const assignBuzzer = useSetRecoilState(buzzersAtomFamily(currentBuzzer));

  useEffect(() => {
    if (isTracking) {
      const unregisteredBuzzers = activeBuzzers.filter(
        ({ id }) => !registeredBuzzers.includes(id)
      );

      const activeBuzzer = unregisteredBuzzers?.[0];
      if (!activeBuzzer) {
        return;
      }

      setCurrentBuzzer(activeBuzzer.id);
      setIsTracking(false);
    }
  }, [activeBuzzers, isTracking]);

  useEffect(() => {
    if (playersWithoutBuzzers && playersWithoutBuzzers.length) {
      setPlayerId(playersWithoutBuzzers[0]);
    } else {
      setPlayerId(null);
      navigate(-1);
    }
  }, [playersWithoutBuzzers]);

  const registerBuzzer = () => {
    setRegisteredBuzzers((buzzers) => [...buzzers, currentBuzzer]);
    assignBuzzer(playerId);
    setIsTracking(true);
  };

  if (!playerId) {
    return null;
  }

  return (
    <main className={styles.container}>
      <CloseButton className={styles.close} onClick={() => navigate(-1)} />

      <h1>Get ready to buzz!</h1>

      <section className={styles.registration}>
        <p>Keep an eye on the screen for your player name.</p>
        <p>
          When it appears, press your{' '}
          <span className={styles.red}>&nbsp;red&nbsp;</span> buzzer to assign
          it to yourself.
        </p>

        <Player size={60} id={playerId} />
        <div className={styles.tracker}>
          <Tracker tracking={isTracking} onAnimationEnd={registerBuzzer} />
        </div>
      </section>
    </main>
  );
};

export default Registration;
